import React from "react";

const SurveyCard = () => {
  return (
    <div className="w-full max-w-md mx-auto bg-white bg-opacity-30 backdrop-blur-xl rounded-lg shadow-lg p-6 text-center">
      {/* Survey Description */}
      <h2 className="text-2xl font-bold text-blue-800">
      የእርሶ ግብረ መልስ ፓርቲችን ግብዓት እና አቅም ነዉ ! 
      </h2>
      <p className="text-gray-600 mb-6">
      በብልፅግና ፓርቲ የህብረተሰቡን የልብ ምት ለመስማት እና አቅጣጫዎችን ለማስቀመጥ የተዘጋጀ የህዝብ አስተያየት መሰብሰቢያ ቅፅ
      {/* የእርሶ ግብረ መልስ ፓርቲችን ግብዓት እና አቅም ነዉ !  */}
      </p>

      {/* Go to Survey Button */}
      <button
        className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
        onClick={() => window.open("https://survey.eth-prosperity.com/survey", "_blank")}
      >
       ግብረ መልሱ መስጫ
      </button>
    </div>
  );
};

export default SurveyCard;
