// // // import React, { useState } from "react";
// // // import axios from "axios";

// // // const Survey = () => {
// // // const sections = [

// // //     {
// // //         section: "ክፍል 1: የግል መረጃ",
// // //         questions: [
// // //           {
// // //             id: 1,
// // //             text: "ዕድሜ",
// // //             options: [
// // //               "ከ18 በታች",
// // //               "18–25 ",
// // //               "26–35",
// // //               "36–45",
// // //               "ከ 45 በላይ "
// // //             ],
// // //             inputFields: [
// // //               { id: "name", placeholder: "name" }, 
// // //               { id: "city", placeholder: "ከተማ" }, // Placeholder for city
// // //               { id: "workplace", placeholder: "የሥራ ቦታ" }, // Placeholder for workplace
// // //             ],
// // //           },
          
// // //           {
// // //             id: 2,
// // //             text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
// // //             options: [
// // //               "የጤና አገልግሎቶች",
// // //               "ትምህርት",
// // //               "መጓጓዣ",
// // //               "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
              
// // //             ],
  
// // //           }
// // //         ]
// // //       },

      


// // //     {
// // //       section: "ክፍል 2: የህዝብ አገልግሎቶች እና አስተዳደር",
// // //       questions: [
// // //         {
// // //           id: 3,
// // //           text: "በአካባቢዎ ባለው የአገልግሎት አሰጣጥ ጥራት ምን ያህል ረክተዋል?",
// // //           options: [
// // //             "በጣም ረክቻለሁ",
// // //             "ረክቻለሁ",
// // //             "ገለልተኛ",
// // //             "አልረካም።",
// // //             "በጣም አልረካም።"
// // //           ]
// // //         },
// // //         {
// // //           id: 4,
// // //           text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
// // //           options: [
// // //             "የጤና አገልግሎቶች",
// // //             "ትምህርት",
// // //             "መጓጓዣ",
// // //             "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
            
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 3: ሰላም እና ደህንነት",
// // //       questions: [
// // //         {
// // //           id: 5,
// // //           text: "በማህበረሰብዎ ውስጥ ምን ያህል ደህንነት ይሰማዎታል?",
// // //           options: [
// // //             "በጣም አስተማማኝ",
// // //             "ደህንነቱ የተጠበቀ",
// // //             "ገለልተኛ",
// // //             "አስተማማኝ ያልሆነ",
// // //             "በጣም አስተማማኝ ያልሆነ"
// // //           ]
// // //         },
// // //         {
// // //           id: 6,
// // //           text: "በአካባቢዎ ያሉ ዋና ዋና የደህንነት ስጋቶች ምንድን ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
// // //           options: [
// // //             "ስርቆት/ዘረፋ",
// // //             "ኃይለኛ ግጭቶች",
// // //             "የፖሊስ መገኘት እጥረት",
            
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 4: የኑሮ ውድነት እና ኢኮኖሚያዊ ሁኔታዎች",
// // //       questions: [
// // //         {
// // //           id: 7,
// // //           text: "አሁን ያለውን የኑሮ ውድነት እንዴት ይመዝኑታል?",
// // //           options: [
// // //             "ተመጣጣኝ",
// // //             "የሚተዳደር",
// // //             "አስቸጋሪ",
// // //             "በጣም አስቸጋሪ"
// // //           ]
// // //         },
// // //         {
// // //           id: 8,
// // //           text: "እርስዎ የሚያጋጥሙዎት ትልቁ የኢኮኖሚ ፈተና ምንድነው? (አንድ ምረጥ)",
// // //           options: [
// // //             "የምግብ ዋጋ መጨመር",
// // //             "የመኖሪያ ቤት ወጪዎች",
// // //             "ዝቅተኛ ደመወዝ / ገቢ",
            
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 5: ኮሪደር ልማት",
// // //       questions: [
// // //         {
// // //           id: 9,
// // //           text: "በአካባቢዎ ያለውን የመጓጓዣ ኮሪደሮች ሂደት እንዴት ይገመግማሉ?",
// // //           options: [
// // //             "በጣም ጥሩ",
// // //             "ጥሩ",
// // //             "ፍትሃዊ",
// // //             "አስቸጋሪ",
// // //             "በጣም አስቸጋሪ"
// // //           ]
// // //         },
// // //         {
// // //           id: 10,
// // //           text: "ለአገናኝ መንገዱ ልማት ምን ማሻሻያዎች አስፈላጊ ናቸው?",
// // //           options: [
// // //             "የተሻሻለ መሠረተ ልማት",
// // //             "የተሻሻለ ግንኙነት",
// // //             "የነባር መንገዶች ጥገና",
           
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 6፡ ለተማሪዎች ምግብ",
// // //       questions: [
// // //         {
// // //           id: 11,
// // //           text: "በአካባቢያችሁ የተማሪዎች የምግብ ፕሮግራሞች በብቃት ተተግብረዋል?",
// // //           options: [
// // //             "አዎ",
// // //             "አይ"
// // //           ]
// // //         },
// // //         {
// // //           id: 12,
// // //           text: "በተማሪ ምግብ ፕሮግራሞች ውስጥ ምን ተግዳሮቶች ታያለህ?",
// // //           options: [
// // //             "ተደራሽነት",
// // //             "የምግብ ጥራት",
// // //             "ወቅታዊ ስርጭት",
            
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 7: የመሠረተ ልማት ግንባታ",
// // //       questions: [
// // //         {
// // //           id: 13,
// // //           text: "በአካባቢያችሁ ባለው የመሰረተ ልማት ዝርጋታ ምን ያህል ረክተዋል?",
// // //           options: [
// // //             "በጣም ረክቻለሁ",
// // //             "ረክቻለሁ",
// // //             "ገለልተኛ",
// // //             "አያረካም",
// // //             "በጣም አያረካም"
// // //           ]
// // //         },
// // //         {
// // //           id: 14,
// // //           text: "የትኞቹ መሰረተ ልማቶች አስቸኳይ ትኩረት ያስፈልጋቸዋል? (የሚመለከተውን ሁሉ ይምረጡ)",
// // //           options: [
// // //             "መንገዶች",
// // //             "ትምህርት ቤቶች",
// // //             "ሆስፒታሎች",
// // //             "የውሃ አቅርቦት",
        
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 8፡ በህገ-ወጥነት ላይ የሚወሰድ እርምጃ",
// // //       questions: [
// // //         {
// // //           id: 15,
// // //           text: "በሕገ-ወጥ ድርጊቶች (ለምሳሌ በመጓጓዣ) ላይ የሚወሰዱ እርምጃዎች ምን ያህል ውጤታማ ናቸው?",
// // //           options: [
// // //             "በጣም ውጤታማ",
// // //             "ውጤታማ",
// // //             "ገለልተኛ",
// // //             "ውጤታማ ያልሆነ",
// // //             "በጣም ውጤታማ ያልሆነ"
// // //           ]
// // //         },
// // //         {
// // //           id: 16,
// // //           text: "ምን ዓይነት ሕገወጥ ተግባራት ቅድሚያ ሊሰጣቸው ይገባል?",
// // //           options: [
// // //             "ፈቃድ የሌላቸው ንግዶች",
// // //             "ሕገ-ወጥ መጓጓዣ",
// // //             "ሙስና",
            
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 9፡ የስራ ፈጠራ",
// // //       questions: [
// // //         {
// // //           id: 17,
// // //           text: "በክልላችሁ የስራ እድል ለመፍጠር የሚደረገውን ጥረት እንዴት ይገመግማሉ?",
// // //           options: [
// // //             "በጣም ጥሩ",
// // //             "ጥሩ",
// // //             "ፍትሃዊ",
// // //             "ውጤታማ ያልሆነ",
// // //             "በጣም ውጤታማ ያልሆነ"
// // //           ]
// // //         },
// // //         {
// // //           id: 18,
// // //           text: "መንግሥት ለሥራ ዕድል ፈጠራ ትኩረት መስጠት ያለበት በየትኞቹ ዘርፎች ነው? (የሚመለከተውን ሁሉ ይምረጡ)",
// // //           options: [
// // //             "ግብርና",
// // //             "ማምረት",
// // //             "ቴክኖሎጂ",
// // //             "አገልግሎቶች",
           
// // //           ]
// // //         }
// // //       ]
// // //     },
// // //     {
// // //       section: "ክፍል 10: የፓርቲውን ቅቡልነት",
// // //       questions: [
// // //         {
// // //           id: 19,
// // //           text: "የብልጽግና ፓርቲ በአካባቢያችሁ ያለውን ተቀባይነት እንዴት ይገልጹታል?",
// // //           options: [
// // //             "በጣም ከፍተኛ",
// // //             "ከፍተኛ",
// // //             "ገለልተኛ",
// // //             "ዝቅተኛ",
// // //             "በጣም ዝቅተኛ"
// // //           ]
// // //         },
// // //         {
// // //           id: 20,
// // //           text: "ፓርቲው ተቀባይነትን ለማሻሻል ምን ላይ ማተኮር አለበት? (የሚመለከተውን ሁሉ ይምረጡ)",
// // //           options: [
// // //             "ማህበራዊ አገልግሎቶች",
// // //             "ደህንነት",
// // //             "መሠረተ ልማት",
// // //             "ሥራ"
// // //           ]
// // //         }
// // //       ]
// // //     }
// // //   ];
  

// // //   const [responses, setResponses] = useState({});

// // //   const handleOptionChange = (questionId, value) => {
// // //     setResponses((prev) => ({
// // //       ...prev,
// // //       [questionId]: { ...prev[questionId], option: value },
// // //     }));
// // //   };

// // //   const handleOtherChange = (questionId, value) => {
// // //     setResponses((prev) => ({
// // //       ...prev,
// // //       [questionId]: { ...prev[questionId], other: value },
// // //     }));
// // //   };

// // //   const handleSubmit = async (e) => {
// // //     e.preventDefault();
// // //     try {
// // //       const response = await fetch('http://127.0.0.1:8000/api/save-survey/', {
// // //         method: 'POST',
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //         },
// // //         body: JSON.stringify(Object.values(responses)),
// // //       });
// // //       if (!response.ok) {
// // //         throw new Error(`HTTP error! status: ${response.status}`);
// // //       }
// // //       alert("Thank you for your feedback!");
// // //     } catch (error) {
// // //       console.error("There was an error submitting the responses:", error);
// // //     }
// // //   };
  

// // //   return (
// // //     <div className="min-h-screen bg-gray-100 flex items-center justify-center">
// // //       <div className="w-full max-w-2xl mx-auto bg-white bg-opacity-30 backdrop-blur-xl rounded-lg shadow-lg p-8">
// // //         <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
// // //           የህዝብ አስተያየት ዳሰሳ መሰብሰቢያ ቅፅ  
// // //           ዓላማ፡- አስተዳደርና አገልግሎት አሰጣጥን ለማሳደግ የህዝብ አስተያየት ማሰባሰብ።
// // //         </h1>
// // //         <form onSubmit={handleSubmit} className="space-y-8">
// // //           {sections.map((section, sectionIndex) => (
// // //             <div key={sectionIndex} className="space-y-6">
// // //               <h2 className="text-xl font-bold text-gray-800">{section.section}</h2>
// // //               {section.questions.map((question) => (
// // //                 <div key={question.id}>
// // //                   <p className="text-lg font-medium text-gray-800 mb-4">{question.text}</p>
// // //                   {/* Render options for selection */}
// // //                   {question.options && (
// // //                     <div className="flex flex-wrap gap-4 md:flex-row md:justify-between">
// // //                       {question.options.map((option, index) => (
// // //                         <label key={index} className="flex flex-col items-center text-gray-600 w-full md:w-auto">
// // //                           <input
// // //                             type="radio"
// // //                             name={`question-${question.id}`}
// // //                             value={index + 1}
// // //                             className="w-5 h-5 text-blue-600 focus:ring-blue-500"
// // //                           />
// // //                           <span className="mt-1">{option}</span>
// // //                         </label>
// // //                       ))}
// // //                     </div>
// // //                   )}

// // //                   {/* Render input fields (e.g., name, city, workplace) */}
// // //                   {question.inputFields && (
// // //                     <div className="flex flex-col gap-4 mt-4">
// // //                       {question.inputFields.map((field) => (
// // //                         <div key={field.id}>
// // //                           <input
// // //                             type="text"
// // //                             name={field.id}
// // //                             placeholder={field.placeholder}
// // //                             className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
// // //                           />
// // //                         </div>
// // //                       ))}
// // //                     </div>
// // //                   )}

// // //                   {/* Additional text input for other thoughts */}
// // //                   <div className="mt-4">
// // //                     <label className="text-gray-600">
// // //                       ሌላ:
// // //                       <input
// // //                         type="text"
// // //                         name={`other-${question.id}`}
// // //                         placeholder="ሌላ ሃሳብ ካልዎት"
// // //                         className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
// // //                       />
// // //                     </label>
// // //                   </div>
// // //                 </div>
// // //               ))}
// // //             </div>
// // //           ))}
// // //           <button
// // //             type="submit"
// // //             className="w-full py-3 bg-blue-600 text-white rounded-lg font-semibold shadow-md hover:bg-blue-700 transition duration-300"
// // //           >
// // //             Submit Feedback
// // //           </button>
// // //         </form>
// // //       </div>
// // //     </div>
// // //   );

  
// // // };
// // // export default Survey;



// // import React, { useState } from "react";
// // import axios from "axios";

// // const Survey = () => {
// //   const [formData, setFormData] = useState({
// //     name: "",
// //     work: "",
// //     city: "",
// //     answers: {
// //       question_1: {},
// //       question_2: {
// //         selected: [],
// //         other_idea: "",
// //       },
// //       question_3: {
// //         satisfaction: "",
// //         other_idea: "",
// //       },
// //       question_4: {
// //         satisfaction: "",
// //         other_idea: "",
// //       },
// //     },
// //   });
// //   const [loading, setLoading] = useState(false);
// //   const [successMessage, setSuccessMessage] = useState("");
// //   const [errorMessage, setErrorMessage] = useState("");

// //   const sections = [
// //     {
// //         section: "ክፍል 1: የግል መረጃ",
// //         questions: [
// //           {
// //             id: 1,
// //             text: "ዕድሜ",
// //             options: [
// //               "ከ18 በታች",
// //               "18–25 ",
// //               "26–35",
// //               "36–45",
// //               "ከ 45 በላይ "
// //             ],
// //             inputFields: [
// //               { id: "name", placeholder: "name" }, 
// //               { id: "city", placeholder: "ከተማ" }, // Placeholder for city
// //               { id: "workplace", placeholder: "የሥራ ቦታ" }, // Placeholder for workplace
// //             ],
// //           },
          
// //           {
// //             id: 2,
// //             text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
// //             options: [
// //               "የጤና አገልግሎቶች",
// //               "ትምህርት",
// //               "መጓጓዣ",
// //               "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
              
// //             ],
  
// //           }
// //         ]
// //       },

      


// //     {
// //       section: "ክፍል 2: የህዝብ አገልግሎቶች እና አስተዳደር",
// //       questions: [
// //         {
// //           id: 3,
// //           text: "በአካባቢዎ ባለው የአገልግሎት አሰጣጥ ጥራት ምን ያህል ረክተዋል?",
// //           options: [
// //             "በጣም ረክቻለሁ",
// //             "ረክቻለሁ",
// //             "ገለልተኛ",
// //             "አልረካም።",
// //             "በጣም አልረካም።"
// //           ]
// //         },
// //         {
// //           id: 4,
// //           text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
// //           options: [
// //             "የጤና አገልግሎቶች",
// //             "ትምህርት",
// //             "መጓጓዣ",
// //             "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
            
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 3: ሰላም እና ደህንነት",
// //       questions: [
// //         {
// //           id: 5,
// //           text: "በማህበረሰብዎ ውስጥ ምን ያህል ደህንነት ይሰማዎታል?",
// //           options: [
// //             "በጣም አስተማማኝ",
// //             "ደህንነቱ የተጠበቀ",
// //             "ገለልተኛ",
// //             "አስተማማኝ ያልሆነ",
// //             "በጣም አስተማማኝ ያልሆነ"
// //           ]
// //         },
// //         {
// //           id: 6,
// //           text: "በአካባቢዎ ያሉ ዋና ዋና የደህንነት ስጋቶች ምንድን ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
// //           options: [
// //             "ስርቆት/ዘረፋ",
// //             "ኃይለኛ ግጭቶች",
// //             "የፖሊስ መገኘት እጥረት",
            
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 4: የኑሮ ውድነት እና ኢኮኖሚያዊ ሁኔታዎች",
// //       questions: [
// //         {
// //           id: 7,
// //           text: "አሁን ያለውን የኑሮ ውድነት እንዴት ይመዝኑታል?",
// //           options: [
// //             "ተመጣጣኝ",
// //             "የሚተዳደር",
// //             "አስቸጋሪ",
// //             "በጣም አስቸጋሪ"
// //           ]
// //         },
// //         {
// //           id: 8,
// //           text: "እርስዎ የሚያጋጥሙዎት ትልቁ የኢኮኖሚ ፈተና ምንድነው? (አንድ ምረጥ)",
// //           options: [
// //             "የምግብ ዋጋ መጨመር",
// //             "የመኖሪያ ቤት ወጪዎች",
// //             "ዝቅተኛ ደመወዝ / ገቢ",
            
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 5: ኮሪደር ልማት",
// //       questions: [
// //         {
// //           id: 9,
// //           text: "በአካባቢዎ ያለውን የመጓጓዣ ኮሪደሮች ሂደት እንዴት ይገመግማሉ?",
// //           options: [
// //             "በጣም ጥሩ",
// //             "ጥሩ",
// //             "ፍትሃዊ",
// //             "አስቸጋሪ",
// //             "በጣም አስቸጋሪ"
// //           ]
// //         },
// //         {
// //           id: 10,
// //           text: "ለአገናኝ መንገዱ ልማት ምን ማሻሻያዎች አስፈላጊ ናቸው?",
// //           options: [
// //             "የተሻሻለ መሠረተ ልማት",
// //             "የተሻሻለ ግንኙነት",
// //             "የነባር መንገዶች ጥገና",
           
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 6፡ ለተማሪዎች ምግብ",
// //       questions: [
// //         {
// //           id: 11,
// //           text: "በአካባቢያችሁ የተማሪዎች የምግብ ፕሮግራሞች በብቃት ተተግብረዋል?",
// //           options: [
// //             "አዎ",
// //             "አይ"
// //           ]
// //         },
// //         {
// //           id: 12,
// //           text: "በተማሪ ምግብ ፕሮግራሞች ውስጥ ምን ተግዳሮቶች ታያለህ?",
// //           options: [
// //             "ተደራሽነት",
// //             "የምግብ ጥራት",
// //             "ወቅታዊ ስርጭት",
            
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 7: የመሠረተ ልማት ግንባታ",
// //       questions: [
// //         {
// //           id: 13,
// //           text: "በአካባቢያችሁ ባለው የመሰረተ ልማት ዝርጋታ ምን ያህል ረክተዋል?",
// //           options: [
// //             "በጣም ረክቻለሁ",
// //             "ረክቻለሁ",
// //             "ገለልተኛ",
// //             "አያረካም",
// //             "በጣም አያረካም"
// //           ]
// //         },
// //         {
// //           id: 14,
// //           text: "የትኞቹ መሰረተ ልማቶች አስቸኳይ ትኩረት ያስፈልጋቸዋል? (የሚመለከተውን ሁሉ ይምረጡ)",
// //           options: [
// //             "መንገዶች",
// //             "ትምህርት ቤቶች",
// //             "ሆስፒታሎች",
// //             "የውሃ አቅርቦት",
        
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 8፡ በህገ-ወጥነት ላይ የሚወሰድ እርምጃ",
// //       questions: [
// //         {
// //           id: 15,
// //           text: "በሕገ-ወጥ ድርጊቶች (ለምሳሌ በመጓጓዣ) ላይ የሚወሰዱ እርምጃዎች ምን ያህል ውጤታማ ናቸው?",
// //           options: [
// //             "በጣም ውጤታማ",
// //             "ውጤታማ",
// //             "ገለልተኛ",
// //             "ውጤታማ ያልሆነ",
// //             "በጣም ውጤታማ ያልሆነ"
// //           ]
// //         },
// //         {
// //           id: 16,
// //           text: "ምን ዓይነት ሕገወጥ ተግባራት ቅድሚያ ሊሰጣቸው ይገባል?",
// //           options: [
// //             "ፈቃድ የሌላቸው ንግዶች",
// //             "ሕገ-ወጥ መጓጓዣ",
// //             "ሙስና",
            
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 9፡ የስራ ፈጠራ",
// //       questions: [
// //         {
// //           id: 17,
// //           text: "በክልላችሁ የስራ እድል ለመፍጠር የሚደረገውን ጥረት እንዴት ይገመግማሉ?",
// //           options: [
// //             "በጣም ጥሩ",
// //             "ጥሩ",
// //             "ፍትሃዊ",
// //             "ውጤታማ ያልሆነ",
// //             "በጣም ውጤታማ ያልሆነ"
// //           ]
// //         },
// //         {
// //           id: 18,
// //           text: "መንግሥት ለሥራ ዕድል ፈጠራ ትኩረት መስጠት ያለበት በየትኞቹ ዘርፎች ነው? (የሚመለከተውን ሁሉ ይምረጡ)",
// //           options: [
// //             "ግብርና",
// //             "ማምረት",
// //             "ቴክኖሎጂ",
// //             "አገልግሎቶች",
           
// //           ]
// //         }
// //       ]
// //     },
// //     {
// //       section: "ክፍል 10: የፓርቲውን ቅቡልነት",
// //       questions: [
// //         {
// //           id: 19,
// //           text: "የብልጽግና ፓርቲ በአካባቢያችሁ ያለውን ተቀባይነት እንዴት ይገልጹታል?",
// //           options: [
// //             "በጣም ከፍተኛ",
// //             "ከፍተኛ",
// //             "ገለልተኛ",
// //             "ዝቅተኛ",
// //             "በጣም ዝቅተኛ"
// //           ]
// //         },
// //         {
// //           id: 20,
// //           text: "ፓርቲው ተቀባይነትን ለማሻሻል ምን ላይ ማተኮር አለበት? (የሚመለከተውን ሁሉ ይምረጡ)",
// //           options: [
// //             "ማህበራዊ አገልግሎቶች",
// //             "ደህንነት",
// //             "መሠረተ ልማት",
// //             "ሥራ"
// //           ]
// //         }
// //       ]
// //     }
// //     // Add additional sections/questions as needed...
// //   ];

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData((prev) => ({
// //       ...prev,
// //       [name]: value,
// //     }));
// //   };

// //   const handleNestedInputChange = (questionId, field, value) => {
// //     setFormData((prev) => ({
// //       ...prev,
// //       answers: {
// //         ...prev.answers,
// //         [questionId]: {
// //           ...prev.answers[questionId],
// //           [field]: value,
// //         },
// //       },
// //     }));
// //   };

// //   const handleOptionChange = (questionId, value) => {
// //     setFormData((prev) => ({
// //       ...prev,
// //       answers: {
// //         ...prev.answers,
// //         [questionId]: {
// //           ...prev.answers[questionId],
// //           age_group: value,
// //         },
// //       },
// //     }));
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     setLoading(true);

// //     try {
// //       const response = await axios.post(
// //         "http://127.0.0.1:8000/api/survey-response2/",
// //         formData
// //       );
// //       setSuccessMessage("Survey submitted successfully!");
// //       setErrorMessage("");
// //       setLoading(false);
// //     } catch (error) {
// //       setErrorMessage("Error submitting the survey. Please try again.");
// //       setLoading(false);
// //     }
// //   };

// //   return (
// //     <div className="survey-container">
// //       <h1>Survey</h1>
// //       <form onSubmit={handleSubmit}>
// //         {sections.map((section, index) => (
// //           <div key={index} className="section">
// //             <h2>{section.section}</h2>
// //             {section.questions.map((question) => (
// //               <div key={question.id} className="question">
// //                 <p>{question.text}</p>
// //                 {question.options &&
// //                   question.options.map((option, idx) => (
// //                     <div key={idx}>
// //                       <label>
// //                         <input
// //                           type="radio"
// //                           name={`question-${question.id}`}
// //                           value={option}
// //                           onChange={() => handleOptionChange(question.id, option)}
// //                         />
// //                         {option}
// //                       </label>
// //                     </div>
// //                   ))}
// //                 {question.inputFields &&
// //                   question.inputFields.map((input) => (
// //                     <div key={input.id}>
// //                       <input
// //                         type="text"
// //                         name={input.id}
// //                         placeholder={input.placeholder}
// //                         onChange={(e) =>
// //                           handleNestedInputChange(
// //                             question.id,
// //                             input.id,
// //                             e.target.value
// //                           )
// //                         }
// //                       />
// //                     </div>
// //                   ))}
// //               </div>
// //             ))}
// //           </div>
// //         ))}
// //         <button type="submit" disabled={loading}>
// //           {loading ? "Submitting..." : "Submit"}
// //         </button>
// //       </form>
// //       {successMessage && <p className="success-message">{successMessage}</p>}
// //       {errorMessage && <p className="error-message">{errorMessage}</p>}
// //     </div>
// //   );
// // };

// // export default Survey;



// import React, { useState } from "react";
// import axios from "axios";

// const Survey = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     work: "",
//     city: "",
//     answers: {
//       question_1: {},
//       question_2: {
//         selected: [],
//         other_idea: "",
//       },
//       question_3: {
//         satisfaction: "",
//         other_idea: "",
//       },
//       question_4: {
//         satisfaction: "",
//         other_idea: "",
//       },
//     },
//   });
//   const [loading, setLoading] = useState(false);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const sections = [
//     {
//         section: "ክፍል 1: የግል መረጃ",
//         questions: [
//           {
//             id: 1,
//             text: "ዕድሜ",
//             options: [
//               "ከ18 በታች",
//               "18–25 ",
//               "26–35",
//               "36–45",
//               "ከ 45 በላይ "
//             ],
//             inputFields: [
//               { id: "name", placeholder: "name" }, 
//               { id: "city", placeholder: "ከተማ" }, // Placeholder for city
//               { id: "workplace", placeholder: "የሥራ ቦታ" }, // Placeholder for workplace
//             ],
//           },
          
//           {
//             id: 2,
//             text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
//             options: [
//               "የጤና አገልግሎቶች",
//               "ትምህርት",
//               "መጓጓዣ",
//               "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
              
//             ],
  
//           }
//         ]
//       },

      


//     {
//       section: "ክፍል 2: የህዝብ አገልግሎቶች እና አስተዳደር",
//       questions: [
//         {
//           id: 3,
//           text: "በአካባቢዎ ባለው የአገልግሎት አሰጣጥ ጥራት ምን ያህል ረክተዋል?",
//           options: [
//             "በጣም ረክቻለሁ",
//             "ረክቻለሁ",
//             "ገለልተኛ",
//             "አልረካም።",
//             "በጣም አልረካም።"
//           ]
//         },
//         {
//           id: 4,
//           text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
//           options: [
//             "የጤና አገልግሎቶች",
//             "ትምህርት",
//             "መጓጓዣ",
//             "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
            
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 3: ሰላም እና ደህንነት",
//       questions: [
//         {
//           id: 5,
//           text: "በማህበረሰብዎ ውስጥ ምን ያህል ደህንነት ይሰማዎታል?",
//           options: [
//             "በጣም አስተማማኝ",
//             "ደህንነቱ የተጠበቀ",
//             "ገለልተኛ",
//             "አስተማማኝ ያልሆነ",
//             "በጣም አስተማማኝ ያልሆነ"
//           ]
//         },
//         {
//           id: 6,
//           text: "በአካባቢዎ ያሉ ዋና ዋና የደህንነት ስጋቶች ምንድን ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
//           options: [
//             "ስርቆት/ዘረፋ",
//             "ኃይለኛ ግጭቶች",
//             "የፖሊስ መገኘት እጥረት",
            
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 4: የኑሮ ውድነት እና ኢኮኖሚያዊ ሁኔታዎች",
//       questions: [
//         {
//           id: 7,
//           text: "አሁን ያለውን የኑሮ ውድነት እንዴት ይመዝኑታል?",
//           options: [
//             "ተመጣጣኝ",
//             "የሚተዳደር",
//             "አስቸጋሪ",
//             "በጣም አስቸጋሪ"
//           ]
//         },
//         {
//           id: 8,
//           text: "እርስዎ የሚያጋጥሙዎት ትልቁ የኢኮኖሚ ፈተና ምንድነው? (አንድ ምረጥ)",
//           options: [
//             "የምግብ ዋጋ መጨመር",
//             "የመኖሪያ ቤት ወጪዎች",
//             "ዝቅተኛ ደመወዝ / ገቢ",
            
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 5: ኮሪደር ልማት",
//       questions: [
//         {
//           id: 9,
//           text: "በአካባቢዎ ያለውን የመጓጓዣ ኮሪደሮች ሂደት እንዴት ይገመግማሉ?",
//           options: [
//             "በጣም ጥሩ",
//             "ጥሩ",
//             "ፍትሃዊ",
//             "አስቸጋሪ",
//             "በጣም አስቸጋሪ"
//           ]
//         },
//         {
//           id: 10,
//           text: "ለአገናኝ መንገዱ ልማት ምን ማሻሻያዎች አስፈላጊ ናቸው?",
//           options: [
//             "የተሻሻለ መሠረተ ልማት",
//             "የተሻሻለ ግንኙነት",
//             "የነባር መንገዶች ጥገና",
           
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 6፡ ለተማሪዎች ምግብ",
//       questions: [
//         {
//           id: 11,
//           text: "በአካባቢያችሁ የተማሪዎች የምግብ ፕሮግራሞች በብቃት ተተግብረዋል?",
//           options: [
//             "አዎ",
//             "አይ"
//           ]
//         },
//         {
//           id: 12,
//           text: "በተማሪ ምግብ ፕሮግራሞች ውስጥ ምን ተግዳሮቶች ታያለህ?",
//           options: [
//             "ተደራሽነት",
//             "የምግብ ጥራት",
//             "ወቅታዊ ስርጭት",
            
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 7: የመሠረተ ልማት ግንባታ",
//       questions: [
//         {
//           id: 13,
//           text: "በአካባቢያችሁ ባለው የመሰረተ ልማት ዝርጋታ ምን ያህል ረክተዋል?",
//           options: [
//             "በጣም ረክቻለሁ",
//             "ረክቻለሁ",
//             "ገለልተኛ",
//             "አያረካም",
//             "በጣም አያረካም"
//           ]
//         },
//         {
//           id: 14,
//           text: "የትኞቹ መሰረተ ልማቶች አስቸኳይ ትኩረት ያስፈልጋቸዋል? (የሚመለከተውን ሁሉ ይምረጡ)",
//           options: [
//             "መንገዶች",
//             "ትምህርት ቤቶች",
//             "ሆስፒታሎች",
//             "የውሃ አቅርቦት",
        
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 8፡ በህገ-ወጥነት ላይ የሚወሰድ እርምጃ",
//       questions: [
//         {
//           id: 15,
//           text: "በሕገ-ወጥ ድርጊቶች (ለምሳሌ በመጓጓዣ) ላይ የሚወሰዱ እርምጃዎች ምን ያህል ውጤታማ ናቸው?",
//           options: [
//             "በጣም ውጤታማ",
//             "ውጤታማ",
//             "ገለልተኛ",
//             "ውጤታማ ያልሆነ",
//             "በጣም ውጤታማ ያልሆነ"
//           ]
//         },
//         {
//           id: 16,
//           text: "ምን ዓይነት ሕገወጥ ተግባራት ቅድሚያ ሊሰጣቸው ይገባል?",
//           options: [
//             "ፈቃድ የሌላቸው ንግዶች",
//             "ሕገ-ወጥ መጓጓዣ",
//             "ሙስና",
            
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 9፡ የስራ ፈጠራ",
//       questions: [
//         {
//           id: 17,
//           text: "በክልላችሁ የስራ እድል ለመፍጠር የሚደረገውን ጥረት እንዴት ይገመግማሉ?",
//           options: [
//             "በጣም ጥሩ",
//             "ጥሩ",
//             "ፍትሃዊ",
//             "ውጤታማ ያልሆነ",
//             "በጣም ውጤታማ ያልሆነ"
//           ]
//         },
//         {
//           id: 18,
//           text: "መንግሥት ለሥራ ዕድል ፈጠራ ትኩረት መስጠት ያለበት በየትኞቹ ዘርፎች ነው? (የሚመለከተውን ሁሉ ይምረጡ)",
//           options: [
//             "ግብርና",
//             "ማምረት",
//             "ቴክኖሎጂ",
//             "አገልግሎቶች",
           
//           ]
//         }
//       ]
//     },
//     {
//       section: "ክፍል 10: የፓርቲውን ቅቡልነት",
//       questions: [
//         {
//           id: 19,
//           text: "የብልጽግና ፓርቲ በአካባቢያችሁ ያለውን ተቀባይነት እንዴት ይገልጹታል?",
//           options: [
//             "በጣም ከፍተኛ",
//             "ከፍተኛ",
//             "ገለልተኛ",
//             "ዝቅተኛ",
//             "በጣም ዝቅተኛ"
//           ]
//         },
//         {
//           id: 20,
//           text: "ፓርቲው ተቀባይነትን ለማሻሻል ምን ላይ ማተኮር አለበት? (የሚመለከተውን ሁሉ ይምረጡ)",
//           options: [
//             "ማህበራዊ አገልግሎቶች",
//             "ደህንነት",
//             "መሠረተ ልማት",
//             "ሥራ"
//           ]
//         }
//       ]
//     }
//     // Add additional sections/questions as needed...
//   ];

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleNestedInputChange = (questionId, field, value) => {
//     setFormData((prev) => ({
//       ...prev,
//       answers: {
//         ...prev.answers,
//         [questionId]: {
//           ...prev.answers[questionId],
//           [field]: value,
//         },
//       },
//     }));
//   };

//   const handleOptionChange = (questionId, value) => {
//     setFormData((prev) => ({
//       ...prev,
//       answers: {
//         ...prev.answers,
//         [questionId]: {
//           ...prev.answers[questionId],
//           age_group: value,
//         },
//       },
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const response = await axios.post(
//         "http://127.0.0.1:8000/api/survey-response2/",
//         formData
//       );
//       setSuccessMessage("Survey submitted successfully!");
//       setErrorMessage("");
//       setLoading(false);
//     } catch (error) {
//       setErrorMessage("Error submitting the survey. Please try again.");
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="survey-container">
//       <h1>Survey</h1>
//       <form onSubmit={handleSubmit}>
//         {sections.map((section, index) => (
//           <div key={index} className="section">
//             <h2>{section.section}</h2>
//             {section.questions.map((question) => (
//               <div key={question.id} className="question">
//                 <p>{question.text}</p>
//                 {question.options &&
//                   question.options.map((option, idx) => (
//                     <div key={idx}>
//                       <label>
//                         <input
//                           type="radio"
//                           name={`question-${question.id}`}
//                           value={option}
//                           onChange={() => handleOptionChange(question.id, option)}
//                         />
//                         {option}
//                       </label>
//                     </div>
//                   ))}
//                 {question.inputFields &&
//                   question.inputFields.map((input) => (
//                     <div key={input.id}>
//                       <input
//                         type="text"
//                         name={input.id}
//                         placeholder={input.placeholder}
//                         onChange={(e) =>
//                           handleNestedInputChange(
//                             question.id,
//                             input.id,
//                             e.target.value
//                           )
//                         }
//                       />
//                     </div>
//                   ))}
//               </div>
//             ))}
//           </div>
//         ))}
//         <button type="submit" disabled={loading}>
//           {loading ? "Submitting..." : "Submit"}
//         </button>
//       </form>
//       {successMessage && <p className="success-message">{successMessage}</p>}
//       {errorMessage && <p className="error-message">{errorMessage}</p>}
//     </div>
//   );
// };

// export default Survey;



import React, { useState } from "react";
import axios from "axios";
import logo from "../assets/prospority.png";

const Survey = () => {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    work: "",
    answers: {},
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sections = [
    {
        section: "ክፍል 1: የግል መረጃ",
        questions: [
          {
            id: 1,
            text: "ዕድሜ",
            options: [
              "ከ18 በታች",
              "18–25 ",
              "26–35",
              "36–45",
              "ከ 45 በላይ "
            ],
            inputFields: [
              { id: "name", placeholder: "ስም" }, 
              { id: "city", placeholder: "ክልል/ከተማ" }, // Placeholder for city
              { id: "workplace", placeholder: "የሥራ ቦታ" }, // Placeholder for workplace
            ],
          },
          {
            id: 2,
            text: "ጾታ፡",
            options: [
              "ወንድ",
              "ሴት",
            ],
            // includeOtherIdea: true,
  
          },
          
          {
            id: 3,
            text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
            options: [
              "የጤና አገልግሎቶች",
              "ትምህርት",
              "መጓጓዣ",
              "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
              
            ],
            includeOtherIdea: true,
  
          }
        ]
      },

      


    {
      section: "ክፍል 2: የህዝብ አገልግሎቶች እና አስተዳደር",
      questions: [
        {
          id: 4,
          text: "በአካባቢዎ ባለው የመንገስት አገልግሎት አሰጣጥ ጥራት ፥ ፍጥነት እና ቅልጥፍና  ምን ያህል ረክተዋል?",
          options: [
            "በጣም ረክቻለሁ",
            "ረክቻለሁ",
            "ገለልተኛ",
            "አልረካም።",
            "በጣም አልረካም።"
          ],
          includeOtherIdea: true,
        },
        {
          id: 5,
          text: "በአገልግሎት አሰጣጥ ላይ የበለጠ መሻሻል የሚያስፈልጋቸው የትኞቹ ገጽታዎች ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
          options: [
            "የጤና አገልግሎቶች",
            "ትምህርት",
            "መጓጓዣ",
            "መገልገያዎች (ውሃ ፣ ኤሌክትሪክ፣ ውዘተ…)",
            
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 3: ሰላም እና ደህንነት",
      questions: [
        {
          id: 6,
          text: "በሚኖሩበት አካባቢ ምን ያህል ደህንነት ይሰማዎታል?",
          options: [
            "በጣም አስተማማኝ",
            "ደህንነቱ የተጠበቀ",
            "ገለልተኛ",
            "አስተማማኝ ያልሆነ",
            "በጣም አስተማማኝ ያልሆነ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 7,
          text: "በአካባቢዎ ያሉ ዋና ዋና የደህንነት ስጋቶች ምንድን ናቸው? (የሚመለከተውን ሁሉ ይምረጡ)",
          options: [
            "ስርቆት/ዘረፋ",
            "ኃይለኛ ግጭቶች",
            "የፖሊስ መገኘት እጥረት",
            
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 4: የኑሮ ውድነት እና ኢኮኖሚያዊ ሁኔታዎች",
      questions: [
        {
          id: 8,
          text: "አሁን ያለውን የኑሮ ውድነት እንዴት ይመዝኑታል?",
          options: [
            "ተመጣጣኝ",
            "የሚተዳደር",
            "አስቸጋሪ",
            "በጣም አስቸጋሪ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 9,
          text: "እርስዎ የሚያጋጥሙዎት ትልቁ የኢኮኖሚ ፈተና ምንድነው? (አንድ ምረጥ)",
          options: [
            "የምግብ ዋጋ መጨመር",
            "የመኖሪያ ቤት ወጪዎች",
            "ዝቅተኛ ደመወዝ / ገቢ",
            
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 5: ኮሪደር ልማት",
      questions: [
        {
          id: 10,
          text: "በአካባቢዎ ያለውን ኮሪደር ልማት  ሂደት እንዴት ይገመግማሉ?",
          options: [
            "በጣም ጥሩ",
            "ጥሩ",
            "ፍትሃዊ",
            "አስቸጋሪ",
            "በጣም አስቸጋሪ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 11,
          text: "በኮሪደር  ልማት ዉስጥ  ምን ማሻሻያዎች አስፈላጊ ናቸው?",
          options: [
            "የተሻሻለ መሠረተ ልማት",
            "የተሻሻለ ግንኙነት",
            "የነባር መንገዶች ጥገና",
           
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 6፡ ለተማሪዎች ምግብ",
      questions: [
        {
          id: 12,
          text: "በአካባቢያችሁ የተማሪዎች የምግብ ፕሮግራሞች በብቃት ተተግብረዋል?",
          options: [
            "አዎ",
            "አይ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 13,
          text: "በተማሪ ምግብ ፕሮግራሞች ውስጥ ምን ተግዳሮቶች ታያለህ?",
          options: [
            "ተደራሽነት",
            "የምግብ ጥራት",
            "ወቅታዊ ስርጭት",
            
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 7: የመሠረተ ልማት ግንባታ",
      questions: [
        {
          id: 14,
          text: "በአካባቢያችሁ ባለው የመሰረተ ልማት ዝርጋታ ምን ያህል ረክተዋል?",
          options: [
            "በጣም ረክቻለሁ",
            "ረክቻለሁ",
            "ገለልተኛ",
            "አያረካም",
            "በጣም አያረካም"
          ],
          includeOtherIdea: true,
        },
        {
          id: 15,
          text: "የትኞቹ መሰረተ ልማቶች አስቸኳይ ትኩረት ያስፈልጋቸዋል? (የሚመለከተውን ሁሉ ይምረጡ)",
          options: [
            "መንገዶች",
            "ትምህርት ቤቶች",
            "ሆስፒታሎች",
            "የውሃ አቅርቦት",
        
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 8፡ በህገ-ወጥነት ላይ የሚወሰድ እርምጃ",
      questions: [
        {
          id: 16,
          text: "በሕገ-ወጥ ድርጊቶች (ለምሳሌ በመጓጓዣ) ላይ የሚወሰዱ እርምጃዎች ምን ያህል ውጤታማ ናቸው?",
          options: [
            "በጣም ውጤታማ",
            "ውጤታማ",
            "ገለልተኛ",
            "ውጤታማ ያልሆነ",
            "በጣም ውጤታማ ያልሆነ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 17,
          text: "ምን ዓይነት ሕገወጥ ተግባራት ቅድሚያ ሊሰጣቸው ይገባል?",
          options: [
            "ፈቃድ የሌላቸው ንግዶች",
            "ሕገ-ወጥ መጓጓዣ",
            "ሙስና",
            
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 9፡ የስራ ፈጠራ",
      questions: [
        {
          id: 18,
          text: "በአከባቢዎት የስራ እድል ለመፍጠር የሚደረገውን ጥረት እንዴት ይገመግማሉ?",
          options: [
            "በጣም ጥሩ",
            "ጥሩ",
            "ፍትሃዊ",
            "ውጤታማ ያልሆነ",
            "በጣም ውጤታማ ያልሆነ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 19,
          text: "መንግሥት ለሥራ ዕድል ፈጠራ ትኩረት መስጠት ያለበት በየትኞቹ ዘርፎች ነው? (የሚመለከተውን ሁሉ ይምረጡ)",
          options: [
            "ግብርና",
            "ማምረት",
            "ቴክኖሎጂ",
            "አገልግሎቶች",
           
          ],
          includeOtherIdea: true,
        }
      ]
    },
    {
      section: "ክፍል 10: የፓርቲውን ቅቡልነት",
      questions: [
        {
          id: 20,
          text: "የብልጽግና ፓርቲ በአካባቢያችሁ ያለውን ተቀባይነት እንዴት ይገልጹታል?",
          options: [
            "በጣም ከፍተኛ",
            "ከፍተኛ",
            "ገለልተኛ",
            "ዝቅተኛ",
            "በጣም ዝቅተኛ"
          ],
          includeOtherIdea: true,
        },
        {
          id: 21,
          text: "ፓርቲው ተቀባይነትን ለማሻሻል ምን ላይ ማተኮር አለበት? (የሚመለከተውን ሁሉ ይምረጡ)",
          options: [
            "ማህበራዊ አገልግሎቶች",
            "ደህንነት",
            "መሠረተ ልማት",
            "ሥራ"
          ],
          includeOtherIdea: true,
        }
      ]
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionChange = (questionId, value) => {
    setFormData((prev) => ({
      ...prev,
      answers: {
        ...prev.answers,
        [questionId]: {
          ...prev.answers[questionId],
          age_group: value, // Update specific field
        },
      },
    }));
  };

  const handleOtherIdeaChange = (questionId, value) => {
    setFormData((prev) => ({
      ...prev,
      answers: {
        ...prev.answers,
        [questionId]: {
          ...prev.answers[questionId],
          other_idea: value, // Add "other_idea"
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.eth-prosperity.com/api/survey-response2/",
        formData
      );
      setSuccessMessage("Survey submitted successfully!");
      setErrorMessage("");
      setLoading(false);
    } catch (error) {
      setErrorMessage("Error submitting the survey. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 flex items-center justify-center p-4">
      <div className="w-full max-w-5xl mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden">
        <div className="p-8 space-y-8">
        <div className="flex justify-center items-center">
            {/* <img src={logo} alt="Logo" className="h-16 w-16 mr-4" /> */}
            <img src={logo} alt="Logo" className="h-16 w-16 mb-1 sm:mb-0 sm:mr-4" style={{ marginLeft: '5px' }} />
            <h1 className="text-4xl font-extrabold text-center text-gray-900 tracking-wide">
                   ብልፅግና ፓርቲ
            </h1>
          </div>
          <p className="text-lg text-center text-gray-700">
          የዚህ የህዝብ ሃሳብ ዳሰሳ አስፈላጊነት የህብረተሰባችንን ጥያቄ ከማወቅ ባለፈ ለቀጣይ የእርምት ስራችን እንደ ግብዓት የምንጠቀመዉ ትልቅ አበርክቶ ያለዉ ዳሰሳ ነዉ ፨ ስለ ቀና ትብብሮት እናመሰግናልን፨
          </p>
          <form
            onSubmit={handleSubmit}
            className="space-y-12"
          >
            {sections.map((section, index) => (
              <div
                key={index}
                className="space-y-8 bg-gray-50 p-6 rounded-xl shadow-inner"
              >
                <h2 className="text-2xl font-semibold text-gray-800">
                  {section.section}
                </h2>
                {section.questions.map((question) => (
                  <div
                    key={question.id}
                    className="space-y-4"
                  >
                    <p className="text-lg font-medium text-gray-700">
                      {question.text}
                    </p>
                    {/* Options */}
                    {question.options && (
                      <div className="flex flex-wrap gap-4 md:flex-row md:justify-start">
                        {question.options.map((option, idx) => (
                          <label
                            key={idx}
                            className="flex items-center space-x-3"
                          >
                            <input
                              type="radio"
                              name={`question-${question.id}`}
                              value={option}
                              className="w-5 h-5 text-blue-600 focus:ring focus:ring-blue-300"
                              onChange={() => handleOptionChange(question.id, option)}
                            />
                            <span className="text-gray-600">{option}</span>
                          </label>
                        ))}
                      </div>
                    )}
                    {/* Input fields */}
                    {question.inputFields && (
                      <div className="space-y-4">
                        {question.inputFields.map((input) => (
                          <input
                            key={input.id}
                            type="text"
                            name={input.id}
                            placeholder={input.placeholder}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                            onChange={handleInputChange}
                          />
                        ))}
                      </div>
                    )}
                    {/* Additional idea */}
                    {question.includeOtherIdea && (
                      <textarea
                        placeholder="ሌላ ሃሳብ ካልዎት ..."
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-pink-300"
                        onChange={(e) =>
                          handleOtherIdeaChange(question.id, e.target.value)
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
            <button
              type="submit"
              className={`w-full py-4 text-lg font-bold text-white rounded-xl shadow-lg transition duration-300 ${
                loading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gradient-to-r from-blue-500 to-purple-600 hover:from-purple-600 hover:to-pink-500"
              }`}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Feedback"}
            </button>
          </form>
          {successMessage && (
            <p className="text-center text-green-600 text-lg font-medium">
              {successMessage}
            </p>
          )}
          {errorMessage && (
            <p className="text-center text-red-600 text-lg font-medium">
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
  
  
};

export default Survey;
